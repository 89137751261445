var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mb-4 mt-4"},[_c('ClearLocalStorage',{attrs:{"default_cols":_vm.cols_default,"storage_name":_vm.cols_storage_name,"cols_list":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols_default=$event}}})],1),_vm._l((_vm.updated_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{attrs:{"headers":_vm.updated_cols,"items":_vm.service_centers,"loading":_vm.loading,"no-data-text":"Сервисные центры отсутствуют"},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.address)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.address)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.address))])],1)]}},{key:"item.telephone",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.min_width},[(item.telephone)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.telephone)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.telephone))])],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.min_width_mail},[(item.email)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.email)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.email))])],1)]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.min_width},[_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.website)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]),_c('span',[_vm._v(_vm._s(item.website))])],1)]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }